import React, { useState } from "react";
import "./table.css";
import { RadioButton } from "@progress/kendo-react-inputs";
import DatePicker from "react-datepicker";

export const TableColumnFilter = (props) => {
  const [tempFilter, setTempFilter] = useState(
    props.columnFilterData && props.columnFilterData
  );
  const [isTempFilter, setIsTempFilter] = useState(false);

  console.log(props.tempRange, props.columnName);

  const handleFilter = (e) => {
    const filtered = props.columnFilterData.filter((i) =>
      typeof i === "object"
        ? i.props.data.objectValue
            .replace(/ /g, "")
            .toLowerCase()
            .includes(e.replace(/ /g, "").toLowerCase())
        : i
            .replace(/ /g, "")
            .toLowerCase()
            .includes(e.replace(/ /g, "").toLowerCase())
    );
    setTempFilter([...filtered]);
    setIsTempFilter(true);
  };

  function timeToMinutes(input) {
    if (!input) {
      return 0;
    }
    let [_, hour, minute, meridiem] = input.match(
      /(\d{1,2})\:(\d{1,2})([ap])?/i
    );
    hour = Number(hour) + (meridiem === "p" ? 12 : 0);
    return hour * 60 + Number(minute);
  }

  const sortingData = (value) => {
    let returnValue = "";
    if (value.format === "time") {
      returnValue = tempFilter.sort((a, b) =>
        timeToMinutes(a) < timeToMinutes(b) ? -1 : 1
      );
    } else {
      console.log("this is tempfIlter", tempFilter);
      returnValue = value.sorting_column
        ? tempFilter.sort((a, b) =>
            a.toLowerCase() < b.toLowerCase() ? -1 : 1
          )
        : tempFilter
            .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
            .reverse();
    }
    return returnValue;
  };

  console.log(props.columnFilterData);

  return (
    <div style={{ position: "relative" }}>
      <div
        className="Column_filter_main_box"
        style={{
          transform:
            props.totalCount / 2 < props.columnId === true
              ? "translate3d(-20px, 0px, 0px)"
              : `translate3d(${230 - parseInt(props.columnWidth)}px, 0px, 0px)`,
        }}
      >
        {props.data.order_column && (
          <div
            style={{ marginBottom: "10px" }}
            className="button_hover"
            onClick={() => props.sortAtoZ(true, props.data)}
          >
            {props.columnName === "date_of_observation" ||
            props.columnName === "time_of_observation"
              ? "Sort Ascending"
              : "Sort A to Z"}
          </div>
        )}
        {props.data.order_column && (
          <div
            style={{ marginBottom: "10px" }}
            className="button_hover"
            onClick={() => props.sortAtoZ(false, props.data)}
          >
            {props.columnName === "date_of_observation" ||
            props.columnName === "time_of_observation"
              ? "Sort Descending"
              : "Sort Z to A"}
          </div>
        )}
        <div
          style={{
            marginBottom: "10px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => props.clearFilter(props.columnName, props.data)}
        >
          Clear filter
        </div>
        {props.data.is_range && (
          <div className="table_radio_main_box">
            {props.data.range_title.map((i) => {
              return (
                <div key={i}>
                  <RadioButton
                    // className="details-radio"
                    label={i}
                    name={props.data.id}
                    // // value={v}
                    onClick={() =>
                      props.onHandleRangeChange({
                        type: i,
                        data: props.data,
                      })
                    }
                    checked={
                      props.tempRange?.[props.columnName] === i ? true : false
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
        {props.data.is_range &&
        props.tempRange?.[props.columnName] === props.data.range_config ? (
          <div className="date_range_box">
            <DatePicker
              dateFormat={"yyyy-mm-dd"}
              // selected={new Date()}
              onChange={(e) => props.onHandleDateRangeChange(e, props.data)}
              startDate={
                props?.filteredItemTemp?.[props.data.range_start_param]
                  ? new Date(
                      props.filteredItemTemp[props.data.range_start_param]
                    )
                  : new Date()
              }
              endDate={
                props?.filteredItemTemp?.[props.data.range_end_param]
                  ? new Date(props.filteredItemTemp[props.data.range_end_param])
                  : null
              }
              selectsRange
              inline
            />
            {props?.filteredItemTemp?.[props.data.range_start_param] &&
            props?.filteredItemTemp?.[props.data.range_end_param] === null ? (
              <div className="error_message">Please select end date!</div>
            ) : null}
          </div>
        ) : (
          <>
            <div style={{ width: "100%" }}>
              <span class="k-icon k-font-icon k-i-zoom Column_filter_search_icon"></span>
              <input
                className="Column_filter_search_box"
                onChange={(e) => handleFilter(e.target.value)}
              />
            </div>
            <div className="Column_filter_item_box">
              {sortingData(props.data).map((i, id) => (
                <div key={id}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                    className="button_hover"
                    onClick={() =>
                      props.onHandleFilterData({ value: i, data: props.data })
                    }
                  >
                    {props?.filteredItemTemp?.[props.columnName] &&
                    props.filteredItemTemp[props.columnName].find((j) =>
                      typeof j === "object" ? j.id === i.id : j === i
                    ) ? (
                      <span class="k-icon k-font-icon k-i-check"></span>
                    ) : (
                      <div
                        style={{
                          width: "16px",
                          height: "16px",
                          minWidth: "16px",
                        }}
                      />
                    )}
                    {/* <span class="k-icon k-font-icon k-i-check"></span> */}
                    {typeof i === "object"
                      ? !Array.isArray(i) && i?.props.data.objectValue
                      : i}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="Column_filter_footer_box">
          <div
            className="Column_filter_footer_cancel"
            onClick={() => props.setShowFilter(false)}
          >
            Cancel
          </div>
          <div
            className="Column_filter_footer_ok"
            onClick={() => props.FilterApply()}
          >
            Ok
          </div>
        </div>
      </div>
    </div>
  );
};
